import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/146/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Retinol Boost Noční krém
 
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct singleproduct-new">
                <div className="prodimgcont prod-img">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Night_Cream_Box+Bottle.png"
                      alt="NEUTROGENA® Retinol Boost Noční krém"
                    />
                  </div>
                </div>
                <div className="prod-info info new-product">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Retinol Boost Noční krém
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                      Tento lehký, rychle se vstřebávající noční krém uvolňuje do vrchní vrstvy pokožky čistý Retinol a tak <strong>účinně bojuje proti viditelným známkám stárnutí: vrásky, ztráta pevnosti, unavená pleť, suchost, pigmentové skvrny.</strong> Pro mladší a zdravý vzhled pokožky.<br/>
                      </p>
                      <br/>
                      <p>
                      Tak účinný, že <strong>u 100 % testujících byl potvrzen viditelný efekt.*</strong> 
                      </p>  
                      <br/>
                      <p>
                      <strong>VĚDECKY POTVRZENÉ VÝSLEDKY*/**</strong><br/>
                      OKAMŽITĚ: Hydratuje pro hladší pleť.<br/>
                      ZA 1 TÝDEN: Zlepšuje texturu pokožky (o 32,6 %). Sjednocuje tón pleti (o 29,5 %).<br/>
                      ZA 4 TÝDNY: Zjemňuje vrásky. Snižuje výskyt jemných linek (o 37,7 %).<br/>
                      ZA 8 TÝDNŮ: Redukuje pigmentové skvrny (o 48,3 %).<br/>
                      </p> 
                      <br/>
                      <p>
                      *Vědecké posouzení dermatology, 39 testujících, používání 1x denně. **Průměrné zlepšení v %.<br/>
                      </p> 
                      <br/>
                      <p>
                      <strong>ÚČINNÉ SLOŽKY:</strong><br/>
                      <strong>Čistý Retinol</strong> je forma vitamínu A uznávaná dermatology. Je vědecky prokázáno, že tato vysoce účinná látka zrychluje produkci kolagenu a obnovu buněk na povrchu pokožky.<br/>
                      <strong>Výtažek z myrty</strong> pro zvýšení účinnosti Retinolu.<br/>
                      <strong>Kyselina hyaluronová</strong> pro intenzivní hydrataci a vyplnění pleti.<br/>
                      </p>                
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                    <p>
                    Každý večer naneste pumpičkou do dlaní 2 dávky krému a jemně vmasírujte na obličej a krk. Po použití otočte uzávěr do polohy "zavřeno".<br/>
                    </p>
                    <br/>
                    <p>
                    <strong>Pokud s Retinolem začínáte:</strong> Je důležité zavádět Retinol do péče o pleť postupně. V prvních 3 týdnech používejte pouze 2-3x týdně. Pokud vaše pleť přípravek dobře snáší, zvyšte frekvenci použití až na 1x denně.<br/>
                    </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>UPOZORNĚNÍ:</dt>
                    <dd>
                      <p>
                      Zarudnutí, pocit pálení, brnění nebo loupání jsou běžné dočasné projevy potvrzující, že přípravek účinkuje. Pokud vám způsobují diskomfort, omezte používání na 1x každý druhý den, dokud si pokožka nezvykne. Pokud kožní reakce přetrvává, ukončete používání.<br/>
                      </p>
                      <br/>
                      <p>
                      Zabraňte kontaktu s očima.<br/>
                      </p>
                      <br/>
                      <p>
                      Následující ráno použijte přípravek na ochranu před slunečním zářením, protože retinol může zvýšit citlivost na slunce.<br/>
                      </p>
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>50 ml</dd>
                  </dl>
                </div>
                <div id="fb_35"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
